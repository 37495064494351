@font-face {
  font-family: Notification;
  src: url("notification.4adaffe7.eot");
  src: url("notification.4adaffe7.eot#iefixs3g3t9") format("embedded-opentype"), url("notification.5b25f080.woff") format("woff"), url("notification.93a9544c.ttf") format("truetype"), url("notification.a2e063d9.svg#notification") format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  z-index: 999999;
  width: 320px;
  max-height: calc(100% - 30px);
  padding: 0 15px;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden auto;
}

.notification {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  opacity: .9;
  background-color: #ccc;
  border-radius: 2px;
  margin-top: 15px;
  padding: 15px 15px 15px 58px;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  box-shadow: 0 0 12px #999;
}

.notification .title {
  margin: 0 0 5px;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transition: all .4s;
  transform: translate3d(0, 0, 0);
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transition: all .4s;
  transform: translate3d(100%, 0, 0);
}

.notification:before {
  text-align: center;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  font-family: Notification;
  font-size: 28px;
  line-height: 28px;
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "";
}

@font-face {
  font-family: Continuum Bold;
  src: url("contb-webfont.e415f958.woff2") format("woff2"), url("contb-webfont.54d9d252.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Continuum Light;
  src: url("contl-webfont.0221ce1b.woff2") format("woff2"), url("contl-webfont.9d8f9961.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Continuum Medium;
  src: url("contm-webfont.f1b73dff.woff2") format("woff2"), url("contm-webfont.82af84ac.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#root {
  position: fixed;
  inset: 0;
}

@keyframes gradiant-animation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

#root div.gradiant-background {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 / 400% 400%;
  flex-direction: column;
  height: 100%;
  animation: 15s infinite gradiant-animation;
  display: flex;
  overflow: hidden;
}

#root canvas.drifter-stars {
  position: fixed;
}

#root div.flex {
  display: flex;
}

#root div.flex.column {
  flex-direction: column;
}

#root div.flex.centered {
  justify-content: center;
  align-items: center;
}

#root div.spacer {
  flex: 1px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #0000003d;
  border-radius: 8px;
}

#root div.dev-container {
  color: #fff;
  user-select: none;
  background: #00000061;
  border-radius: 10px;
  align-items: center;
  width: 285px;
  margin-top: 50px;
  padding: 10px;
  box-shadow: 0 0 11px 4px #fff3;
}

#root div.dev-container div.dev-name-avatar {
  align-items: center;
  display: flex;
}

#root div.dev-container div.dev-name-avatar img.dev-avatar {
  object-fit: contain;
  width: 64px;
  height: 64px;
  margin-right: 10px;
}

#root div.dev-container div.dev-name-avatar div.dev-name {
  font-size: 1.5rem;
  font-weight: 500;
}

#root div.dev-container div.dev-social {
  border-top: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  padding: 10px 0 0;
  display: flex;
}

#root div.dev-container div.dev-social a {
  color: #fff;
}

#root div.dev-container div.dev-social svg.svg-inline--fa, #root div.dev-container div.dev-social svg {
  color: #fff;
  width: 32px;
  height: 32px;
}

div.progress-overlay {
  background: #000000bf;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#root div.project-list > div.project-card {
  background: #0000003d;
  border-radius: 5px;
  flex-direction: column;
  padding: 15px 20px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

#root div.project-list > div.project-card > * {
  overflow-wrap: break-word;
  white-space: normal;
}

#root div.project-list > div.project-card > div.header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#root div.project-list > div.project-card > div.header > span.name {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
  font-size: 1.2em;
  font-weight: 500;
  overflow: hidden;
}

#root div.project-list > div.project-card > div.content {
  background: #0000003d;
  border-radius: 5px;
  flex-direction: column;
  flex: 1px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
}

#root div.project-list > div.project-card > div.content > :not(:last-child):not(div.spacer) {
  margin-bottom: 10px;
}

#root div.project-list > div.project-card > div.content > div.thumbnail-container {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

#root div.project-list > div.project-card > div.content > div.thumbnail-container > div.img-wrapper {
  max-width: 100%;
  position: relative;
}

#root div.project-list > div.project-card > div.content > div.thumbnail-container > div.img-wrapper > span.image-count {
  --size: 20px;
  border-radius: calc(var(--size) / 2);
  color: #fff;
  font-size: calc(var(--size) * .4);
  height: var(--size);
  right: calc(var(--size) / 2 * -1);
  top: calc(var(--size) / 2 * -1);
  width: var(--size);
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
}

#root div.project-list > div.project-card > div.content > div.thumbnail-container > div.img-wrapper > img.thumbnail {
  object-fit: contain;
  max-width: 100%;
  height: 150px;
}

#root div.project-list > div.project-card > div.content > span.description, #root div.project-list > div.project-card > div.content > div.description {
  color: #fff;
}

#root div.project-list > div.project-card > div.content > span.description > p:first-child, #root div.project-list > div.project-card > div.content > div.description > p:first-child {
  margin-top: 0;
}

#root div.project-list > div.project-card > div.content > span.description > p:last-child, #root div.project-list > div.project-card > div.content > div.description > p:last-child {
  margin-bottom: 0;
}

#root div.project-list > div.project-card > div.content > span.description a, #root div.project-list > div.project-card > div.content > div.description a {
  color: #7070ff;
}

#root div.project-list > div.project-card > .badge {
  background: #0000003d;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 1px;
  left: -33px;
  transform: rotate(-45deg);
  box-shadow: inset 0 0 0 4px #0000003d;
}

#root div.project-list > div.project-card > .badge > svg {
  fill: #ffd70070;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

#root div.project-list > div.project-card.skeleton > div.header > span.name {
  background: #0000003d;
  border-radius: 5px;
  width: 200px;
  margin-left: 0;
}

#root div.project-list > div.project-card.skeleton > div.content > div.thumbnail-container > div.img-wrapper {
  background: #0000003d;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  margin: 0 5px;
}

#root div.project-list > div.project-card.skeleton > div.content > div.description {
  background: #0000003d;
  border-radius: 5px;
  flex: 1px;
  min-height: 100px;
  margin: 0 5px 10px;
}

@keyframes loading {
  0% {
    left: -150px;
  }

  100% {
    left: 100%;
  }
}

#root div.project-list > div.project-card.skeleton:after {
  content: "";
  background: linear-gradient(to right, #0000, #0000002d, #0000);
  width: 100px;
  animation: 2s linear infinite loading;
  position: absolute;
  top: -10px;
  bottom: -10px;
  transform: rotate(10deg);
}

div.page.protected {
  flex-direction: column;
  flex: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.page.protected.recaptcha > div.captcha-container {
  color: #fff;
  background: #0000003d;
  border-radius: .5em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em;
  display: flex;
  position: relative;
}

div.page.protected.recaptcha > div.captcha-container > div.error {
  color: #fff;
  background: #ff5252;
  border-radius: .25em;
  padding: .5em;
  font-size: .8em;
}

div.page.protected.recaptcha > div.captcha-container.loading > :not(.loading-overlay) {
  visibility: hidden;
}

div.page.protected.recaptcha > div.captcha-container.loading > div.loading-overlay {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

div.icon-list {
  --item-size: 24px;
  --item-icon-size: 20px;
  align-items: center;
  display: flex;
}

div.icon-list > div.icon-list-item {
  border-radius: calc(var(--item-size) / 2);
  box-sizing: border-box;
  height: var(--item-size);
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  display: flex;
  overflow: hidden;
}

div.icon-list > div.icon-list-item > svg {
  height: var(--item-icon-size);
}

div.icon-list.full {
  --item-size: 42px;
  --item-icon-size: 32px;
  flex-wrap: wrap;
  padding: 5px;
  display: flex;
}

div.icon-list.full > span {
  display: none;
}

div.icon-list.full > div.icon-list-item {
  background: #fff;
  margin: 0 7px 10px 0;
  padding: 5px;
  box-shadow: 0 0 5px #000;
}

div.icon-list.full > div.icon-list-item > svg {
  border-radius: calc(var(--item-icon-size) / 2);
}

div.icon-list.full > div.icon-list-item > div.name {
  color: #303030;
  white-space: nowrap;
  margin: 0 5px;
  font-weight: 500;
}

div.icon-list.minimal {
  background: #0000003d;
  border-radius: 5px;
  padding: 10px;
}

div.icon-list.minimal > span {
  color: #fff;
  margin-right: 10px;
  font-weight: 500;
}

div.icon-list.minimal > div.icon-list-item {
  width: var(--item-size);
  background: #fff;
  box-shadow: 0 0 5px #ffffffce;
}

div.icon-list.minimal > div.icon-list-item:hover {
  border: 1px solid #0000003d;
  box-shadow: 0 0 5px #fff;
}

div.icon-list.minimal > div.icon-list-item > div.name {
  display: none;
}

div.icon-list.skeleton {
  margin: 0 5px;
}

div.icon-list.skeleton > span {
  color: #0000003d;
}

div.icon-list.skeleton > span.caption {
  background: #0000003d;
  border-radius: 5px;
  width: 80px;
}

div.icon-list.skeleton > div.icon-list-item {
  box-shadow: none;
  background: #0000003d;
}

div.icon-list.skeleton > div.icon-list-item:hover {
  box-shadow: none;
}

div.text-icon {
  --icon-size: 32px;
  border-radius: calc(var(--icon-size) / 2);
  min-height: var(--icon-size);
  min-width: var(--icon-size);
  justify-content: center;
  align-items: center;
  display: flex;
}

#root div.links-list {
  box-sizing: border-box;
  display: flex;
}

#root div.links-list > div.left, #root div.links-list > div.right {
  align-items: center;
  display: flex;
}

#root div.links-list > div.right {
  justify-content: flex-end;
}

#root div.links-list > div.right > a.link-item {
  margin-left: 5px;
}

#root div.links-list > div.left {
  flex: 1px;
}

#root div.links-list > div.left > a.link-item {
  margin-right: 5px;
}

#root div.links-list a.link-item {
  --link-color: #4343e0;
  --padding: 10px;
  --link-icon-size: 16px;
  --icon-margin: 5px;
  background: var(--link-color);
  box-sizing: border-box;
  color: #fff;
  padding: var(--padding);
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: none;
  display: flex;
}

#root div.links-list a.link-item.icon-only {
  padding: 0;
}

#root div.links-list a.link-item > div.text-icon {
  --icon-size: var(--link-icon-size);
}

#root div.links-list a.link-item > svg {
  height: var(--link-icon-size);
  width: var(--link-icon-size);
}

#root div.links-list a.link-item > span.name {
  margin-left: var(--icon-margin);
  text-overflow: hidden;
  white-space: nowrap;
  transition: all .5s;
  display: inline;
  overflow: hidden;
}

#root div.links-list a.link-item.github {
  --link-color: #2e2e2e;
}

#root div.links-list.full a.link-item {
  --link-icon-size: 24px;
  border-radius: 5px;
  height: 44px;
}

#root div.links-list.full a.link-item.icon-only {
  width: 44px;
}

#root div.links-list.minimal {
  background: #fff;
  border-radius: 25px;
  height: 42px;
  padding: 5px;
}

#root div.links-list.minimal a.link-item {
  --text-width: 25px;
  --expanded-width: calc(var(--text-width)  + calc(var(--padding) * 2)  + var(--link-icon-size)  + var(--icon-margin));
  width: var(--expanded-width);
  border-radius: 20px;
  height: 32px;
  transition: all .2s;
}

#root div.links-list.minimal a.link-item.icon-only {
  width: 32px;
}

#root div.links-list.minimal a.link-item:not(:last-child):not(:hover), #root div.links-list.minimal a.link-item:hover ~ a.link-item {
  width: 32px;
  padding: 0;
}

#root div.links-list.minimal a.link-item:not(:last-child):not(:hover) > span.name, #root div.links-list.minimal a.link-item:hover ~ a.link-item > span.name {
  display: none;
}

#root div.links-list.skeleton, #root div.links-list.skeleton a.link-item {
  background: #0000003d;
}

#root div.links-list.skeleton > div.right > a.link-item {
  width: 100px;
}

div.people-list {
  box-sizing: border-box;
  display: flex;
}

div.people-list > div.person {
  border-radius: 26px;
  box-shadow: 0 0 5px #000;
}

div.people-list > div.person > img, div.people-list > div.person svg {
  background: #fff;
  border-radius: 16px;
  width: 32px;
  height: 32px;
}

div.people-list.full {
  flex-wrap: wrap;
  padding: 5px;
}

div.people-list.full > div.person {
  background: #303030;
  align-items: center;
  margin: 0 7px 10px 0;
  padding: 5px;
  display: flex;
}

div.people-list.full > div.person > div.name {
  color: #fff;
  white-space: nowrap;
  margin: 0 5px;
  font-weight: 500;
}

div.people-list.minimal > div.person {
  z-index: calc(var(--total-count)  - var(--index));
  background: #fff;
  transition: all .4s;
}

div.people-list.minimal > div.person:not(:first-child) {
  margin-left: -28px;
}

div.people-list.minimal > div.person > div.name {
  display: none;
}

div.people-list.minimal:hover > div.person:not(:first-child) {
  margin-left: 5px !important;
}

div.people-list.skeleton > div.person {
  background: #0000003d;
  width: 32px;
  height: 32px;
}

div.project-info.modal-wrapper {
  --padding: 25px;
  --text-color: #3e3e3e;
  padding: var(--padding);
  z-index: 999;
  position: fixed;
  inset: 0;
}

div.project-info.modal-wrapper > div.modal {
  backdrop-filter: blur(5px);
  background: #fffafad9;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 15px 5px #000;
}

div.project-info.modal-wrapper > div.modal > span.close {
  --size: 32px;
  border-radius: calc(var(--size) / 2);
  color: #fff;
  font-size: var(--size);
  height: var(--size);
  right: calc(var(--size) / 2 * -1);
  top: calc(var(--size) / 2 * -1);
  user-select: none;
  width: var(--size);
  background: #000000d9;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  transform: rotate(45deg);
}

div.project-info.modal-wrapper > div.modal > div.content {
  padding: var(--padding);
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

div.project-info.modal-wrapper > div.modal > div.content > header > h2 {
  color: var(--text-color);
  margin: 0 0 10px;
}

div.project-info.modal-wrapper > div.modal > div.content > main {
  flex: 1px;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 600px) {
  div.project-info.modal-wrapper > div.modal > div.content > main {
    flex-direction: column;
  }

  div.project-info.modal-wrapper > div.modal > div.content > main > div.carousel {
    margin-bottom: 5px;
    margin-right: 0;
  }

  div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container {
    margin-top: 5px;
    margin-left: 0;
  }
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.carousel {
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1px;
  margin-right: 5px;
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container {
  flex-direction: column;
  flex: 1px;
  margin-left: 5px;
  display: flex;
  overflow: hidden;
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container > div.info {
  flex: 1px;
  overflow: hidden auto;
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container > div.info > h3:first-child {
  margin-top: 0;
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container > div.info > h3.people {
  margin-bottom: 5px;
}

div.project-info.modal-wrapper > div.modal > div.content > main > div.info-container > div.links-list {
  margin-top: 10px;
}

div.carousel {
  background: var(--background-color);
  display: flex;
  position: relative;
  overflow: hidden;
}

div.carousel.light {
  --background-color: #000000d1;
  --button-color: #3e3e3ead;
  --controls-color: #6f6f6fde;
  --controls-color-active: #ffffffde;
  --shadow-color: transparent;
}

div.carousel.dark {
  --background-color: #000000e0;
  --button-color: #463c3c80;
  --controls-color: #55555580;
  --controls-color-active: #ffffffde;
  --shadow-color: #000;
}

div.carousel > button.arrow {
  z-index: 1;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

div.carousel > button.arrow:after {
  border-color: var(--controls-color);
  box-sizing: border-box;
  content: "";
  border-style: solid;
  width: 2em;
  height: 2em;
  transform: rotate(45deg);
}

div.carousel > button.arrow:hover {
  background: var(--button-color);
  box-shadow: 0 0 15px 3px var(--shadow-color);
}

div.carousel > button.arrow:hover:after {
  content: "";
  border-color: var(--controls-color-active);
}

div.carousel > button.arrow.previous {
  left: 0;
}

div.carousel > button.arrow.previous:after {
  border-width: 0 0 1em 1em;
  margin-left: 7px;
}

div.carousel > button.arrow.next {
  right: 0;
}

div.carousel > button.arrow.next:after {
  border-width: 1em 1em 0 0;
  margin-right: 7px;
}

div.carousel > div.slider-wrapper {
  flex: 1px;
  margin: 10px;
  overflow: hidden;
}

div.carousel > div.slider-wrapper > div.slider {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  transition: all 1s;
}

div.carousel > div.slider-wrapper > div.slider > div.slide {
  width: 100%;
  height: 100%;
  display: inline-block;
}

div.carousel > div.slider-wrapper > div.slider > div.slide > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

div.carousel > ul.indicator {
  justify-content: center;
  align-items: center;
  margin: 1em;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

div.carousel > ul.indicator > li {
  background: var(--controls-color);
  border: 1px solid #0000003d;
  border-radius: 1em;
  width: 1em;
  height: 1em;
}

div.carousel > ul.indicator > li:not(:last-child) {
  margin-right: 5px;
}

div.carousel > ul.indicator > li.current {
  background: var(--controls-color-active);
}

#root nav.top-nav-bar {
  --nav-bg-color: #0000003d;
  --nav-border-color: #00000017;
  --nav-height: 50px;
  --nav-padding: 5px;
  --nav-expander-width: 200px;
  --nav-overlay-bg-color: #0003;
  --nav-item-bg-color: #0000004f;
  --nav-item-corner-radius: 4px;
  --nav-item-height: calc(var(--nav-height)  - (var(--nav-padding) * 2));
  background-color: var(--nav-bg-color);
  border-bottom: 1px solid var(--nav-border-color);
  box-sizing: border-box;
  height: var(--nav-height);
  padding: var(--nav-padding);
  z-index: 100;
  flex-direction: row;
  align-items: center;
  display: flex;
  box-shadow: 0 0 5px #00000080;
}

@media screen and (width <= 600px) {
  #root nav.top-nav-bar > ul > li > a.nav-item {
    display: none !important;
  }

  #root nav.top-nav-bar a.nav-item.expand {
    display: flex !important;
  }

  #root nav.top-nav-bar > ul {
    background-color: var(--nav-bg-color);
    border-left: 1px solid var(--nav-border-color);
    box-sizing: border-box;
    flex-direction: column;
    width: 0;
    padding: 0;
    transition: width .5s, border-width .5s;
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 5px #65656580;
    justify-content: flex-start !important;
    align-items: stretch !important;
  }

  #root nav.top-nav-bar > ul > li.splitter {
    width: calc(var(--nav-expander-width)  - 11px) !important;
    height: 2px !important;
  }

  #root nav.top-nav-bar > ul > li:not(:first-child) {
    margin: 5px 0 0 !important;
  }

  #root nav.top-nav-bar.expanded > div.nav-overlay {
    right: var(--nav-expander-width);
    display: block !important;
  }

  #root nav.top-nav-bar.expanded a.nav-item:not(.brand) {
    display: flex !important;
  }

  #root nav.top-nav-bar.expanded a.nav-item.brand {
    left: var(--nav-padding);
    position: absolute;
  }

  #root nav.top-nav-bar.expanded a.nav-item.expand {
    right: var(--nav-padding);
    position: absolute;
  }

  #root nav.top-nav-bar.expanded > ul {
    width: var(--nav-expander-width);
    border-width: 1px;
    padding: 5px;
  }
}

#root nav.top-nav-bar > div.nav-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  top: var(--nav-height);
  z-index: 101;
  transition: right .5s;
  display: none;
  position: fixed;
}

#root nav.top-nav-bar a.nav-item {
  background-color: var(--nav-item-bg-color);
  border-radius: var(--nav-item-corner-radius);
  color: #fff;
  height: var(--nav-item-height);
  user-select: none;
  align-items: center;
  padding: 0 15px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

#root nav.top-nav-bar a.nav-item:hover {
  box-shadow: 0 0 5px #65656580;
}

#root nav.top-nav-bar a.nav-item.expand {
  display: none;
}

#root nav.top-nav-bar > a.brand {
  padding: 0 5px;
}

#root nav.top-nav-bar > a.brand:hover {
  box-shadow: 0 0 5px #65656580;
}

#root nav.top-nav-bar > a.brand > img {
  background: #fff;
  border-radius: 14px;
  height: 28px;
}

#root nav.top-nav-bar > a.brand > span {
  color: #fff;
  margin-left: 5px;
  font-weight: 500;
}

#root nav.top-nav-bar > ul {
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  list-style: none;
  display: flex;
}

#root nav.top-nav-bar > ul > li:not(:first-child) {
  margin-left: 5px;
}

#root nav.top-nav-bar > ul > li > a {
  justify-content: space-between;
}

#root nav.top-nav-bar > ul > li > a.active {
  color: #545454;
  background-color: #fff;
}

#root nav.top-nav-bar > ul > li > a > span {
  margin-left: 10px;
}

#root nav.top-nav-bar > ul > li.splitter {
  background-color: var(--nav-item-bg-color);
  width: 2px;
  height: 40px;
}

#root div.page {
  z-index: 1;
  flex: 1px;
  display: flex;
}

/*# sourceMappingURL=index.59624d6f.css.map */
