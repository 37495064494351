div.icon-list {
    --item-size: 24px;
    --item-icon-size: 20px;

    align-items: center;
    display: flex;

    > div.icon-list-item {
        align-items: center;
        display: flex;
        border-radius: calc(var(--item-size) / 2);
        box-sizing: border-box;
        height: var(--item-size);
        justify-content: center;
        margin-right: 3px;
        overflow: hidden;

        > svg {
            height: var(--item-icon-size);
        }
    }

    &.full {
        --item-size: 42px;
        --item-icon-size: 32px;

        display: flex;
        flex-wrap: wrap;
        padding: 5px;

        > span {
            display: none;
        }

        > div.icon-list-item {
            background: #ffffff;
            box-shadow: 0 0 5px 0 #000;
            padding: 5px;
            margin: 0px 7px 10px 0px;

            > svg {
                border-radius: calc(var(--item-icon-size) / 2);
            }

            > div.name {
                color: #303030;
                font-weight: 500;
                margin: 0px 5px;
                white-space: nowrap;
            }
        }
    }

    &.minimal {
        background: #0000003d;
        border-radius: 5px;
        padding: 10px 10px;

        > span {
            color: #ffffff;
            font-weight: 500;
            margin-right: 10px;
        }

        > div.icon-list-item {
            background: #ffffff;
            box-shadow: 0 0 5px 0 #ffffffce;
            width: var(--item-size);

            &:hover {
                box-shadow: 0 0 5px 0 #ffffff;
                border: 1px solid #0000003d;
            }

            > div.name {
                display: none;
            }
        }
    }

    &.skeleton {
        margin: 0px 5px;

        > span {
            color: #0000003d;

            &.caption {
                background: #0000003d;
                border-radius: 5px;
                width: 80px;
            }
        }

        > div.icon-list-item {
            background: #0000003d;
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }
    }
}
