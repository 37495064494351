@font-face {
    font-family: 'Continuum Bold';
    src: url('./fonts/continuum/contb-webfont.woff2') format('woff2'), url('./fonts/continuum/contb-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Continuum Light';
    src: url('./fonts/continuum/contl-webfont.woff2') format('woff2'), url('./fonts/continuum/contl-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Continuum Medium';
    src: url('./fonts/continuum/contm-webfont.woff2') format('woff2'), url('./fonts/continuum/contm-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

body {
    margin: 0;
    height: 100vh;
    width: 100vw;;
}

#root {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @keyframes gradiant-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    div.gradiant-background {
        animation: gradiant-animation 15s ease infinite;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    canvas.drifter-stars {
        position: fixed;
    }

    div.flex {
        display: flex;

        &.column {
            flex-direction: column;
        }

        &.centered {
            align-items: center;
            justify-content: center;
        }
    }

    div.spacer {
        flex: 1 1 1px;
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #0000003d;
}
