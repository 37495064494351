div.people-list {
    box-sizing: border-box;
    display: flex;

    > div.person {
        border-radius: 26px;
        box-shadow: 0 0 5px 0 #000;

        > img,
        svg {
            background: #ffffff;
            border-radius: 16px;
            height: 32px;
            width: 32px;
        }
    }

    &.full {
        padding: 5px;
        flex-wrap: wrap;

        > div.person {
            align-items: center;
            background: #303030;
            display: flex;
            padding: 5px;
            margin: 0px 7px 10px 0px;

            > div.name {
                color: #ffffff;
                font-weight: 500;
                margin: 0px 5px;
                white-space: nowrap;
            }
        }
    }

    &.minimal {
        > div.person {
            background: #fff;
            transition: ease 0.4s;
            z-index: calc(var(--total-count) - var(--index));

            &:not(:first-child) {
                margin-left: -28px;
            }

            > div.name {
                display: none;
            }
        }

        &:hover {
            > div.person:not(:first-child) {
                margin-left: 5px !important;
            }
        }
    }

    &.skeleton {
        > div.person {
            background: #0000003d;
            height: 32px;
            width: 32px;
        }
    }
}
