div.page.protected {
    flex: 1 1 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.recaptcha {
        > div.captcha-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1em;
            gap: 1em;
            position: relative;

            background: #0000003d;
            border-radius: 0.5em;
            color: #ffffff;

            > div.error {
                color: #fff;
                background: #ff5252;
                font-size: 0.8em;
                padding: 0.5em;
                border-radius: 0.25em;
            }

            &.loading {
                > :not(.loading-overlay) {
                    visibility: hidden;
                }

                > div.loading-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
