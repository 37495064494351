div.carousel {
    &.light {
        --background-color: #000000d1;
        --button-color: #3e3e3ead;
        --controls-color: #6f6f6fde;
        --controls-color-active: #ffffffde;
        --shadow-color: transparent;
    }

    &.dark {
        --background-color: #000000e0;
        --button-color: #463c3c80;
        --controls-color: #55555580;
        --controls-color-active: #ffffffde;
        --shadow-color: #000;
    }

    background: var(--background-color);
    display: flex;
    overflow: hidden;
    position: relative;

    > button.arrow {
        align-items: center;
        background: none;
        border: none;
        bottom: 0;
        display: flex;
        justify-content: center;
        padding: 10px;
        position: absolute;
        top: 0;
        z-index: 1;

        &::after {
            border-color: var(--controls-color);
            border-style: solid;
            box-sizing: border-box;
            content: '';
            height: 2em;
            transform: rotate(45deg);
            width: 2em;
        }

        &:hover {
            background: var(--button-color);
            box-shadow: 0 0 15px 3px var(--shadow-color);

            &::after {
                content: '';
                border-color: var(--controls-color-active);
            }
        }

        &.previous {
            left: 0;

            &::after {
                border-width: 0 0 1em 1em;
                margin-left: 7px;
            }
        }

        &.next {
            right: 0;

            &::after {
                border-width: 1em 1em 0 0;
                margin-right: 7px;
            }
        }
    }

    > div.slider-wrapper {
        flex: 1 1 1px;
        margin: 10px;
        overflow: hidden;

        > div.slider {
            height: 100%;
            transition: ease 1000ms;
            white-space: nowrap;
            width: 100%;

            > div.slide {
                display: inline-block;
                height: 100%;
                width: 100%;

                > img {
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
    }

    > ul.indicator {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        list-style: none;
        margin: 1em;
        padding: 0;
        position: absolute;
        right: 0;

        > li {
            background: var(--controls-color);
            border: 1px solid #0000003d;
            border-radius: 1em;
            height: 1em;
            width: 1em;

            &:not(:last-child) {
                margin-right: 5px;
            }

            &.current {
                background: var(--controls-color-active);
            }
        }
    }
}
