div.progress-overlay {
    align-items: center;
    background: #000000bf;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
