div.project-info.modal-wrapper {
    --padding: 25px;
    --text-color: #3e3e3e;

    bottom: 0;
    left: 0;
    padding: var(--padding);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;

    > div.modal {
        backdrop-filter: blur(5px);
        background: #fffafad9;
        box-shadow: 0 0 15px 5px #000;
        border-radius: 10px;
        height: 100%;
        position: relative;
        width: 100%;

        > span.close {
            --size: 32px;

            align-items: center;
            background: #000000d9;
            border-radius: calc(var(--size) / 2);
            color: #fff;
            display: flex;
            font-size: var(--size);
            position: absolute;
            justify-content: center;
            height: var(--size);
            right: calc(var(--size) / 2 * -1);
            top: calc(var(--size) / 2 * -1);
            transform: rotate(45deg);
            user-select: none;
            width: var(--size);
        }

        > div.content {
            bottom: 0;
            display: flex;
            flex-direction: column;
            left: 0;
            overflow: hidden;
            padding: var(--padding);
            position: absolute;
            right: 0;
            top: 0;

            > header > h2 {
                color: var(--text-color);
                margin: 0 0 10px 0;
            }

            > main {
                display: flex;
                flex: 1 1 1px;
                overflow: hidden;

                @media screen and (max-width: 600px) {
                    flex-direction: column;

                    > div.carousel {
                        margin-right: 0px;
                        margin-bottom: 5px;
                    }

                    > div.info-container {
                        margin-left: 0px;
                        margin-top: 5px;
                    }
                }

                > div.carousel {
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    flex: 1 1 1px;
                    margin-right: 5px;
                }

                > div.info-container {
                    display: flex;
                    flex: 1 1 1px;
                    flex-direction: column;
                    margin-left: 5px;
                    overflow: hidden;

                    > div.info {
                        flex: 1 1 1px;
                        overflow-x: hidden;
                        overflow-y: auto;

                        > h3 {
                            &:first-child {
                                margin-top: 0px;
                            }

                            &.people {
                                margin-bottom: 5px;
                            }
                        }
                    }

                    > div.links-list {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
