#root div.project-list > div.project-card {
    background: #0000003d;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 15px 20px 20px 20px;
    position: relative;

    > * {
        overflow-wrap: break-word;
        white-space: normal;
    }

    > div.header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        > span.name {
            color: #fff;
            font-size: 1.2em;
            font-weight: 500;
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    > div.content {
        background: #0000003d;
        border-radius: 5px;
        display: flex;
        flex: 1 1 1px;
        flex-direction: column;
        margin: 10px 0px;
        padding: 10px;

        > *:not(:last-child):not(div.spacer) {
            margin-bottom: 10px;
        }

        > div.thumbnail-container {
            display: flex;
            justify-content: center;
            margin-top: 5px;

            > div.img-wrapper {
                max-width: 100%;
                position: relative;

                > span.image-count {
                    --size: 20px;

                    align-items: center;
                    background: #00000080;
                    border-radius: calc(var(--size) / 2);
                    color: #ffffff;
                    display: inline-flex;
                    font-size: calc(var(--size) * 0.4);
                    height: var(--size);
                    justify-content: center;
                    position: absolute;
                    right: calc(var(--size) / 2 * -1);
                    top: calc(var(--size) / 2 * -1);
                    width: var(--size);
                }

                > img.thumbnail {
                    object-fit: contain;
                    height: 150px;
                    max-width: 100%;
                }
            }
        }

        > span.description, > div.description {
            color: #fff;

            > p {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: #7070ff;
            }
        }
    }

    > .badge {
        align-items: center;
        background: #0000003d;
        display: flex;
        height: 24px;
        justify-content: center;
        width: 100px;
        transform: rotate(-45deg);
        position: absolute;
        top: 1px;
        left: -33px;
        box-shadow: inset 0px 0px 0px 4px #0000003d;

        > svg {
            fill: #ffd70070;
            height: 16px;
            margin-right: 5px;
            width: 16px;
        }
    }

    &.skeleton {
        > div.header {
            > span.name {
                background: #0000003d;
                border-radius: 5px;
                margin-left: 0;
                width: 200px;
            }
        }

        > div.content {
            > div.thumbnail-container {
                > div.img-wrapper {
                    background: #0000003d;
                    border-radius: 5px;
                    height: 150px;
                    margin: 0px 5px;
                    width: 100%;
                }
            }

            > div.description {
                background: #0000003d;
                border-radius: 5px;
                flex: 1 1 1px;
                margin: 0px 5px 10px 5px;
                min-height: 100px;
            }
        }

        @keyframes loading {
            0% {
                left: -150px;
            }
            100% {
                left: 100%;
            }
        }

        &::after {
            animation: loading 2s infinite linear;
            background: linear-gradient(to right, #00000000, #0000002d, #00000000);
            bottom: -10px;
            content: '';
            position: absolute;
            top: -10px;
            transform: rotate(10deg);
            width: 100px;
        }
    }
}
