#root nav.top-nav-bar {
    --nav-bg-color: #0000003d;
    --nav-border-color: #00000017;
    --nav-height: 50px;
    --nav-padding: 5px;
    --nav-expander-width: 200px;
    --nav-overlay-bg-color: #00000033;
    --nav-item-bg-color: #0000004f;
    --nav-item-corner-radius: 4px;
    --nav-item-height: calc(var(--nav-height) - (var(--nav-padding) * 2));

    @media screen and (max-width: 600px) {
        >ul>li>a.nav-item {
            display: none !important;
        }

        a.nav-item.expand {
            display: flex !important;
        }

        >ul {
            align-items: stretch !important;
            background-color: var(--nav-bg-color);
            border-left: 1px solid var(--nav-border-color);
            bottom: 0;
            box-shadow: 0 0 5px 0 #65656580;
            box-sizing: border-box;
            justify-content: flex-start !important;
            flex-direction: column;
            padding: 0;
            position: absolute;
            right: 0;
            top: 50px;
            transition: width 0.5s, border-width 0.5s ease;
            width: 0px;

            >li {
                &.splitter {
                    height: 2px !important;
                    width: calc(var(--nav-expander-width) - 11px) !important;
                }

                &:not(:first-child) {
                    margin: 5px 0 0 0 !important;
                }
            }
        }

        &.expanded {
            >div.nav-overlay {
                display: block !important;
                right: var(--nav-expander-width);
            }

            a.nav-item:not(.brand) {
                display: flex !important;
            }

            a.nav-item.brand {
                position: absolute;
                left: var(--nav-padding);
            }

            a.nav-item.expand {
                position: absolute;
                right: var(--nav-padding);
            }

            >ul {
                border-width: 1px;
                padding: 5px;
                width: var(--nav-expander-width);
            }
        }
    }

    align-items: center;
    background-color: var(--nav-bg-color);
    border-bottom: 1px solid var(--nav-border-color);
    box-shadow: 0 0 5px 0 #00000080;
    box-sizing: border-box;
    display: flex;
    height: var(--nav-height);
    flex-direction: row;
    padding: var(--nav-padding);
    z-index: 100;

    >div.nav-overlay {
        bottom: 0;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        top: var(--nav-height);
        transition: right 0.5s ease;
        z-index: 101;
    }

    a.nav-item {
        align-items: center;
        background-color: var(--nav-item-bg-color);
        border-radius: var(--nav-item-corner-radius);
        color: #fff;
        display: flex;
        font-weight: 500;
        height: var(--nav-item-height);
        padding: 0px 15px;
        text-decoration: none;
        user-select: none;

        &:hover {
            box-shadow: 0 0 5px 0 #65656580;
        }

        &.expand {
            display: none;
        }
    }

    >a.brand {
        padding: 0px 5px;

        &:hover {
            box-shadow: 0 0 5px 0 #65656580;
        }

        >img {
            background: white;
            border-radius: 14px;
            height: 28;
        }

        >span {
            color: #ffffff;
            font-weight: 500;
            margin-left: 5px;
        }
    }

    >ul {
        align-items: center;
        display: flex;
        list-style: none;
        justify-content: flex-end;
        margin: 0px;

        >li {
            &:not(:first-child) {
                margin-left: 5px;
            }

            >a {
                justify-content: space-between;

                &.active {
                    background-color: white;
                    color: #545454;
                }

                >span {
                    margin-left: 10px;
                }
            }

            &.splitter {
                width: 2px;
                height: 40px;
                background-color: var(--nav-item-bg-color);
            }
        }
    }
}