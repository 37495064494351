#root div.dev-container {
    align-items: center;
    background: #00000061;
    border-radius: 10px;
    box-shadow: 0 0 11px 4px #ffffff33;
    color: white;
    margin-top: 50px;
    padding: 10px;
    user-select: none;
    width: 285px;

    div.dev-name-avatar {
        display: flex;
        align-items: center;

        img.dev-avatar {
            height: 64px;
            object-fit: contain;
            width: 64px;
            margin-right: 10px;
        }

        div.dev-name {
            font-size: 1.5rem;
            font-weight: 500;
        }
    }

    div.dev-social {
        align-items: center;
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 10px 0px 0px 0px;
        gap: 5px;

        & a {
            color: white;
        }

        & svg.svg-inline--fa,
        & svg {
            color: #fff;
            height: 32px;
            width: 32px;
        }
    }
}