#root div.links-list {
    box-sizing: border-box;
    display: flex;

    > div.left,
    > div.right {
        align-items: center;
        display: flex;
    }

    > div.right {
        justify-content: flex-end;

        > a.link-item {
            margin-left: 5px;
        }
    }

    > div.left {
        flex: 1 1 1px;

        > a.link-item {
            margin-right: 5px;
        }
    }

    a.link-item {
        --link-color: #4343e0;
        --padding: 10px;
        --link-icon-size: 16px;
        --icon-margin: 5px;

        align-items: center;
        background: var(--link-color);
        box-sizing: border-box;
        color: white;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: var(--padding);
        text-decoration: none;

        &.icon-only {
            padding: 0;
        }

        > div.text-icon {
            --icon-size: var(--link-icon-size);
        }

        > svg {
            height: var(--link-icon-size);
            width: var(--link-icon-size);
        }

        > span.name {
            display: inline;
            transition: ease 0.5s;
            margin-left: var(--icon-margin);
            overflow: hidden;
            text-overflow: hidden;
            white-space: nowrap;
        }

        &.github {
            --link-color: #2e2e2e;
        }
    }

    &.full {
        a.link-item {
            --link-icon-size: 24px;

            border-radius: 5px;
            height: 44px;

            &.icon-only {
                width: 44px;
            }
        }
    }

    &.minimal {
        background: #ffffff;
        border-radius: 25px;
        height: 42px;
        padding: 5px;

        a.link-item {
            --text-width: 25px;

            --expanded-width: calc(var(--text-width) + calc(var(--padding) * 2) + var(--link-icon-size) + var(--icon-margin));

            border-radius: 20px;
            height: 32px;
            transition: ease 0.2s;
            width: var(--expanded-width);

            &.icon-only {
                width: 32px;
            }

            &:not(:last-child):not(:hover),
            &:hover ~ a.link-item {
                padding: 0;
                width: 32px;

                > span.name {
                    display: none;
                }
            }
        }
    }

    &.skeleton {
        background: #0000003d;

        a.link-item {
            background: #0000003d;
        }

        > div.right {
            > a.link-item {
                width: 100px;
            }
        }
    }
}
