div.text-icon {
    --icon-size: 32px;

    align-items: center;
    border-radius: calc(var(--icon-size) / 2);
    display: flex;
    justify-content: center;
    min-height: var(--icon-size);
    min-width: var(--icon-size);
}
